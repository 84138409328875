<i18n>
  {
    "es": {
      "LEFT_ARROW": "Icono de flecha a la izquierda",
      "RIGHT_ARROW": "Icono de flecha a la derecha"
    },
    "en": {
      "LEFT_ARROW": "Left arrow icon",
      "RIGHT_ARROW": "Right arrow icon"
    }
  }
</i18n>

<template>
  <div :class="isDesktop ? 'md:px-9' : 'xl:pl-9 xl:pr-11'">
    <div
      class="relative mt-12 ss:mt-14 md:mt-12 lg:mt-2 pl-2 lg:pl-0.5 ml-2 md:max-w-1400 md:mx-auto md:block"
    >
      <div class="flex flex-col items-start overflow-hidden w-full">
        <div class="w-full mt-4 pb-8">
          <div
            @click="handleChangeLeft()"
            v-if="isDesktop && leftArrow"
            class="h-10 w-10 grid place-items-center absolute top-0 bottom-0 -left-10 my-auto rounded-tr-md rounded-br-md bg-white bg-opacity-40 text-neutral-black-1 cursor-pointer"
          >
            <img
              src="@/assets/svg/icons/black/icons-left-arrow-light.svg"
              class="h-8"
              :alt="t('LEFT_ARROW')"
            />
          </div>
          <div
            @scroll="isDesktop ? null : handleScroll($event)"
            ref="sliderContainer"
            class="flex flex-nowrap space-x-2.5 mx-auto h-full py-0 pl-0 pr-0 translate-x-0 transition-all duration-500 w-full snap-x snap-mandatory"
            :class="isDesktop ? '' : 'overflow-x-scroll scrollbar-hidden'"
          >
            <div
              v-for="(product, index) in products"
              :key="index"
              class="cursor-pointer flex-shrink-0 p-4 mdplus:box-border mdplus:border-1 mdplus:border-transparent mdplus:hover:border-others-grey2 w-[180px] h-[490px] md:w-[260px] md:h-[580px] overflow-y-hidden"
            >
              <Product :data="product" :currency="currency" />
            </div>
          </div>
          <div
            @click="handleChangeRight()"
            v-if="isDesktop && rightArrow"
            class="h-10 w-10 grid place-items-center absolute top-0 bottom-0 -right-10 my-auto rounded-tl-md rounded-bl-md bg-white bg-opacity-40 text-neutral-black-1 cursor-pointer"
          >
            <img
              src="@/assets/svg/icons/black/icons-right-arrow-light.svg"
              class="h-8"
              :alt="t('RIGHT_ARROW')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  blok: Object,
  currency: Object,
  global: Object,
})

const { t } = useI18n()
const { isDesktop } = useDevice()

const sliderContainer = ref(null)
const leftArrow = ref(false)
const rightArrow = ref(true)
const products = ref([])

const productIds = props.blok.productsids.split(',').map((n) => parseInt(n))
const route = props.blok.routeproducts.route
const maxProducts = props.blok?.maxProducts
  ? parseInt(props.blok.maxProducts)
  : null

const globalIdProducts = props.global.products
const globalRouteProducts = props.global.routes

const getProducts = () => {
  if (productIds.length > 0) {
    let productsByIds = productIds.map((id) => {
      return globalIdProducts[id]
    })
    productsByIds.sort((a, b) => b.stock - a.stock)
    products.value = productsByIds
  } else {
    const routeSeotag = route.params.seotag
    const productsRoute = globalRouteProducts[routeSeotag]
    productsRoute.sort((a, b) => b.stock - a.stock)
    products.value = productsRoute
  }

  if (maxProducts) {
    products.value = products.value.slice(0, maxProducts)
  }
}

getProducts()

const maxScroll = computed(() => {
  if (!sliderContainer.value) return 0
  const child = sliderContainer.value.children[0]
  const childWidth = child.clientWidth
  return childWidth * sliderContainer.value.children.length + 1
})

const actualTranslate = ref(0)

const handleScroll = (e) => {
  if (!sliderContainer.value) return
  const containerWidth = sliderContainer.value.clientWidth
  const scrollWidth = sliderContainer.value.scrollWidth
  const scrollLeft = sliderContainer.value.scrollLeft

  actualTranslate.value = scrollLeft

  leftArrow.value = scrollLeft !== 0
  rightArrow.value = scrollLeft + containerWidth !== scrollWidth
}

const handleChangeRight = () => {
  if (!sliderContainer.value) return
  const containerWidth = sliderContainer.value.clientWidth
  const slideAmount = containerWidth * 0.72
  const extraSpace = 1

  if (actualTranslate.value < maxScroll.value - containerWidth) {
    actualTranslate.value = Math.min(
      actualTranslate.value + slideAmount + extraSpace,
      maxScroll.value
    )
    sliderContainer.value.style.transform = `translateX(-${actualTranslate.value}px)`
  }

  leftArrow.value = actualTranslate.value > 0
  rightArrow.value = actualTranslate.value < maxScroll.value - containerWidth
}

const handleChangeLeft = () => {
  if (!sliderContainer.value) return
  const containerWidth = sliderContainer.value.clientWidth
  const slideAmount = containerWidth * 0.92

  if (actualTranslate.value > 0) {
    actualTranslate.value = Math.max(actualTranslate.value - slideAmount, 0)
    sliderContainer.value.style.transform = `translateX(-${actualTranslate.value}px)`
  }

  leftArrow.value = actualTranslate.value > 0
  rightArrow.value = actualTranslate.value < maxScroll.value - containerWidth
}
</script>
